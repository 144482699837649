<script setup lang="ts">
const defaultTitle = 'Advokátní kancelář Tutter – Právní služby v centru Prahy'

useHead({
  htmlAttrs: { lang: 'cs' },
  titleTemplate: title => title ? `${title} | AK Tutter` : defaultTitle,
})

useSeoMeta({
  ogTitle: defaultTitle,
  ogDescription: 'Advokátní kancelář Tutter poskytuje profesionální právní služby s osobním přístupem. S více než 40 lety zkušeností nabízíme poradenství v obchodním, rodinném, trestním a pracovním právu.',
  ogImageAlt: 'Advokátní kancelář Tutter na Karlově náměstí v Praze',
  twitterTitle: defaultTitle,
  twitterDescription: 'Právní služby s více než 40 lety zkušeností. Advokátní kancelář Tutter nabízí profesionální poradenství a zastupování ve všech oblastech práva.',
  twitterImageAlt: 'Advokátní kancelář Tutter na Karlově náměstí v centru Prahy',
  twitterCard: 'summary_large_image',
})
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
