<script setup lang="ts">
import type { NuxtError } from '#app'

defineProps<{
  error: NuxtError
}>()

definePageMeta({
  robots: false,
})

const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <NuxtLayout>
    <div class="container pb-48 pt-32 text-center xl:py-64">
      <h1 class="h1 mb-8 text-7xl">
        {{ error.statusCode }}
      </h1>

      <AppButton @click="handleError">
        Přejít na úvodní stránku
      </AppButton>
    </div>
  </NuxtLayout>
</template>
